* {
  margin: 0;
  padding: 0;
}

body{
  height: 100vh
}

#root{
  height: 100%
}

.homepage {
  background-image: url("./assets/images/Background.jpg");
  height: 100%;
  background-size: cover;
}

.homepage .logo img {
  position: absolute;
  left: 7vw;
  top: 15vh;
  width: 250px;
  height: 200px;
}
.homepage .praying-person img {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 55vw;
  height: 90vh;
}
#pray-button {
  position: absolute;
  right: 20vw;
  bottom: 32vh;
  background-color: #e51e25;
  color: white;
  padding: 10px;
  font-size: 1.5em;
  border: none;
  cursor: pointer;
}
.homepage .pray-btn:hover {
  background-color: #e43e44;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .homepage .logo img {
    width: 200px;
    height: 150px;
  }
  .homepage .praying-person img {
    width: 60vw;
    height: 87vh;
  }
  #pray-button {
    font-size: 20px;
    right: 25vw;
    bottom: 32vh;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .homepage .logo img {
    width: 170px;
    height: 120px;
  }
  .homepage .praying-person img {
    width: 63vw;
    height: 83vh;
  }
  #pray-button {
    font-size: 17px;
    right: 27vw;
    bottom: 31vh;
  }
}
@media (min-width: 700px) and (max-width: 768px) {
  .homepage .logo img {
    width: 130px;
    height: 100px;
  }
  .homepage .praying-person img {
    width: 60vw;
    height: 80vh;
  }
  #pray-button {
    font-size: 15px;
    right: 29vw;
    bottom: 30vh;
  }
}
@media (min-width: 600px) and (max-width: 700px) {
  .homepage .logo img {
    width: 130px;
    height: 100px;
  }
  .homepage .praying-person img {
    width: 65vw;
    height: 77vh;
  }
  #pray-button {
    font-size: 15px;
    right: 29vw;
    bottom: 29vh;
  }
}
@media (max-width: 600px) {
  .homepage .logo img {
    width: 130px;
    height: 100px;
  }
  .homepage .praying-person img {
    width: 100%;
    height: 65vh;
  }
  #pray-button {
    font-size: 15px;
    right: 29vw;
    bottom: 28vh;
  }

  .select-city{
    background: none !important;
  }

  .mainLogo img{
    width: 150px !important;
    right: 0px;
    position: fixed !important;
  }
}
.select-province {
  width: 100%;
  background-image: url("./assets/images/Background1.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: right;
  line-height: 0.5;
}
.select-province .logo {
  margin-top: 10px;
  width: 185px;
}
.select-province .heading {
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
}
.select-province .list1 .list-item,
.select-province .list2 .list-item {
  font-weight: 500;
  padding: 10px 0px 1px 5px;
  color: red;
}
.select-province .list1 .list-item a,
.select-province .list2 .list-item a,
.cities .list-item a {
  color: red;
}
.select-province .list1 .list-item:hover,
.select-province .list2 .list-item:hover,
.cities .list-item:hover {
  background-color: #e51e25;
  color: white;
}
.select-province .list1 .list-item:hover a,
.select-province .list2 .list-item:hover a,
.cities .list-item:hover a {
  color: white;
  text-decoration: none;
}
.select-province .list1 p,
.select-province .list2 p {
  cursor: pointer;
}

.select-city {
  width: 100%;
  background-image: url("./assets/images/PrayingManFaded.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: 160%;
  background-position-y: center;
  height: 100vh;
}
.select-city .logo {
  margin-top: 10px;
  width: 185px;
}
.select-city .province-map {
  width: 100%;
  text-align: center;
}
.select-city .province-map img {
  max-width: 100%;
}
.select-city .cities div {
  padding: 10px 0px 1px 5px;
}
.select-city .cities p {
  font-weight: 500;
  cursor: pointer;
}
.select-city .cities .list-item:hover {
  background-color: #e51e25;
  color: white;
}

.pray-form {
  width: 100%;
  background-image: url("./assets/images/Background1.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: right;
  height: 100vh;
}
.pray-form .logo {
  margin-top: 10px;
  margin-left: 10px;
  width: 185px;
}
.pray-form .person {
  background-image: url("./assets/images/PrayingManFaded.png");
  background-size: 120vh;
  background-clip: border-box;
  background-position-y: top;
}
.pray-form .prayer-form label {
  font-weight: 500;
  font-size: 1.2em;
}
.pray-form .prayer-form input {
  border: 1px solid black;
  padding: 30px 0px 30px 10px;
  border-radius: 0%;
}
.pray-form .submit-btn {
  background-color: #e51e25;
  color: white;
  padding: 10px;
  font-size: 1.5em;
  border: none;
  cursor: pointer;
}
.pray-form .submit-btn:hover {
  background-color: #e43e44;
}

.admin-panel {
  width: 100%;
  background-image: url("./assets/images/PrayingManFaded.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: 160%;
  background-position-y: center;
  height: 100vh;
}
.admin-panel .logo {
  margin-top: 10px;
  width: 185px;
}
.admin-panel .login-form label {
  font-weight: 500;
  font-size: 1.2em;
}
.admin-panel .login-form input {
  border: 1px solid black;
  padding: 30px 0px 30px 10px;
  border-radius: 0%;
}
.admin-panel .submit-btn {
  background-color: #e51e25;
  color: white;
  padding: 10px;
  font-size: 1.5em;
  border: none;
  cursor: pointer;
  width: 100%;
}
.admin-panel .submit-btn:hover {
  background-color: #e43e44;
}

.admin-dashboard {
  background-image: url("./assets/images/Background.jpg");
  height: 100%;
  background-size: cover;
}
.admin-dashboard .logo {
  margin-top: 10px;
  width: 185px;
}
.admin-dashboard .details .total-users-box {
  background-color: #e43e44;
  margin-left: 15px;
  margin-bottom: 15px;
  margin-top: 15px;
}
.admin-dashboard .details .total-users {
  padding: 15px;
  text-align: center;
  color: white;
}
.admin-dashboard table {
  width: 100%;
}

.mt100 {
  margin-top: 100px;
}

.center {
  text-align: center;
}


.confirmation-message{
  font-size: 30px;
  color: #e51e24;
  font-weight: 600;
}

.passwordCol{
  text-align: right;
  padding-top: 20px;
}

.passwordCol a{
  margin-left: 10px;
}


.passwordCol a:hover{
  text-decoration: none;
  color: white;
}

.href{
  color: blue;
}

.href:hover{
  text-decoration: underline;
  cursor: pointer;
}

.success{
  color: green;
  font-weight: 600
}


.error{
  color: red;
  font-weight: 600
}

.select-province p{
  line-height: 1.3em
}

.mainLogo img{
  width: 200px;
  position: absolute;
  bottom: 0px;
}

#termsCheckbox{
  -ms-transform: scale(2);
    -moz-transform: scale(2);
    -webkit-transform: scale(2);
    -o-transform: scale(2);
    transform: scale(2);
    padding: 10px;
    margin-right: 14px;
    margin-left: 6px;
}